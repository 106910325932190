<template>
  <transition name="fade" appear>
    <div class="home">
      <el-row class="search">
        <el-col :span="21"
          ><input
            type="text"
            placeholder="请输入"
            class="et_search"
            v-model="keyWords"
            @keyup.enter="getAudioListByType()"
        /></el-col>
        <el-col :span="3"
          ><button class="search_text" @click="getAudioListByType()">
            搜索
          </button></el-col
        >
      </el-row>
      <el-row class="title">
        <section class="tag flex align-center">
          <div class="view" @click="type_left">
            <i class="el-icon-arrow-left pointer" style="font-size: 18px" />
          </div>
          <div class="tagBox flex align-center">
            <template v-for="(item, index) in typeListShow">
              <div
                :key="index"
                :class="[
                  'tagItem',
                  'pointer',
                  item.audioTypeCode == chooseType ? 'activeTag' : '',
                ]"
                @click="change(index)"
              >
                {{ item.audioTypeName }}
              </div>
            </template>
          </div>

          <div class="view" @click="type_right">
            <i class="el-icon-arrow-right pointer" style="font-size: 18px" />
          </div>
        </section>
        <!-- <el-col :span="2"><div class="f18">分类：</div></el-col>
        <el-col :span="22">
          <section class="navBox">
            <template v-for="(item, index) in typeList">
              <div class="nav_item_type" :key="index" @click="change(index)">
                <div :class="[index == typeIndex ? 'type_true' : 'type_false']">{{ item.audioTypeName }}</div>
              </div>
            </template>
          </section>
        </el-col> -->
      </el-row>

      <section class="navBox2">
        <div class="show" v-show="!show">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <template v-for="(item, index) in AudioList">
          <div
            class="nav_item_festival"
            :key="index"
            @click="goFmDetail(index)"
          >
            <div class="navItem_img_festival hoverBox1">
              <el-image
                :src="item.logo"
                fit="fill"
                style="height: 100%; width: 100%"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="navItem_text__festival hoverText">
              {{ item.audioName }}
            </div>
          </div>
        </template>
      </section>

      <el-pagination
        v-show="show"
        background=""
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :current-page.sync="currPage"
      >
      </el-pagination>

      <!-- <div class="point"><el-pagination background="" layout="prev, pager, next" :total="1000"> </el-pagination></div> -->
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  name: "fm",
  data() {
    return {
      title: "公共管理学课程教学大纲",
      time: "2022年2月14日",
      keyWords: "",
      //数据总量
      total: 0,
      //单页数量
      pageSize: 12,
      //当前页数
      currPage: 1,
      //切换tab
      currTabPage: 1,
      //分类下标
      typeIndex: 0,
      filterUrl: "http://banktrain.pluss.cn/upload/file_202202240916941.pdf",
      //分类
      typeList: [],
      // 节日活动
      AudioList: [],
      //是否有数据
      show: false,
      //个人信息
      userInfo: {},
      //分类取值
      end: 0,
      typeListShow: [],
      //选择分类
      chooseType: "",
      promptCode:'',
    };
  },
  methods: {
    // 修改当前页
    handleCurrentChange(newCurr) {
      this.currPage = newCurr;
      this.getAudioListByType();
    },

    //左页
    type_left() {
      if (this.end > 0) {
        this.typeListShow = this.typeList.slice(this.end - 5, this.end);
        this.end = this.end - 5;
        this.currTabPage -= 1;
      } else {
        // this.$message.error("这是第一页");
        this.$message({
            type: "error",
            message: "这是第一页",
            offset:'400',
          });
      }
      // this.typeIndex = this.end;
      // this.getAudioListByType();
       this.typeIndex=0
        this.getAudioListByType();
        this.change(this.typeIndex)
    },

    //右页
    type_right() {
      if (this.end + 5 < this.typeList.length) {
        this.end = this.end + 5;
        this.typeListShow = this.typeList.slice(this.end, this.end + 5);

        this.currTabPage += 1;
      } else {
        // this.$message.error("已经到底了");
        this.$message({
            type: "error",
            message: "已经到底了",
            offset:'400',
          });
        return;
      }
      // this.typeIndex = 0;
      // conslog.log(this.typeIndex);
      // this.getAudioListByType()
        this.typeIndex=0
        this.getAudioListByType();
        this.change(this.typeIndex)
    },
    //音频类型分类
    getAudioType() {
      var getDate = {};
      var condition = {};
      let a = sessionStorage.getItem("userInfo")
      if(a==null){
        condition.promptCode = "rz"
      }else{
        let promptCode = JSON.parse(sessionStorage.getItem("userInfo")).promptCode; 
        if(promptCode == "kj" || promptCode == "KJ"){
          condition.promptCode = "kj"
        }else if(promptCode == "rz" || promptCode == "RZ"){
          condition.promptCode = "rz"
        }else{
          return
        }
      }
      condition.audioName = this.keyWords;
      getDate.condition = condition;
      getDate.pageSize = 100;
      getDate.currPage = 1;
      this.$api.home.getAudioType1(getDate).then((res) => {
        if ((res.data.code = 200)) {
          this.typeList = res.data.data.data;
          this.chooseType = this.typeList[this.typeIndex].audioTypeCode;
          this.typeListShow = this.typeList.slice(this.end, this.end + 5);
          this.getAudioListByType();
        }
      });
    },
    //根据FM音频分类查询列表
    getAudioListByType() {
      var getDate = {};
      var condition = {};
      condition.audioTypeCode = this.typeListShow[this.typeIndex].audioTypeCode;
      condition.audioName = this.keyWords;
      getDate.condition = condition;
      getDate.pageSize = this.pageSize;
      getDate.currPage = this.currPage;
      this.$api.home.getAudioListByType(getDate).then((res) => {
        if ((res.data.code = 200)) {
          this.AudioList = res.data.data.data;
          this.total = res.data.data.data.length;
          if (this.total > 0) {
            this.show = true;
          } else {
            this.show = false;
          }
        }
      });
    },
    //切换分类
    change(index) {
      this.typeIndex = index;
      this.chooseType = this.typeListShow[this.typeIndex].audioTypeCode;
      this.getAudioListByType();
    },

    // 前往fm详情
    goFmDetail(index) {
      //当前时间
      var currentTime = Date.parse(new Date());
      console.log('this.userInfo',this.userInfo);
      if (this.userInfo!=null&&this.userInfo.expirationTime) {
        //此处待开放
        if (currentTime - this.userInfo.expirationTime >= 0) {
          // this.open();
           this.$message({
            type: "error",
            message: "您的课程已过期，请前往个人中心重新激活后使用",
            offset:'400',
          });
        } else {
        this.$router.push({
          path: "/fmDetail",
          query: {
            audioCode: this.AudioList[index].audioCode,
          },
        });
      }
      } else {
        // this.open();
          this.$message({
            type: "error",
            message: "您未登录或您的课程暂未激活，请前往个人中心激活后使用",
            offset:'400',
          });
      }
    },
    //是否激活
    open() {
      // this.$prompt("您还没有激活此功能，请输入激活码后使用", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   title: "",
      //   inputPlaceholder: "请输入激活码",
      //   showCancelButton: "false",
      //   center: true,
      // })
      //   .then(({ value }) => {
      //     this.$message({
      //       type: "success",
      //       message: "激活成功",
      //     });getAudioType
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "error",
      //       message: "您还没有激活",
      //       offset:'400',
      //     });
      //   });
    },
  },

  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.$store.commit(CHANGE_NAVINDEX, "0");
    this.typeIndex = this.$route.query.index;
    this.getAudioType();

  
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  margin-top: 40px;
  padding: 0 50px;
  background-color: white;
  .search {
    border: 1px solid #fab300;
    border-radius: 6px;
    display: flex;
    height: 53px;
    align-items: center;
    .et_search {
      outline: none;
      font-size: 18px;
      width: 100%;
      padding-left: 30px;
      align-items: flex-start;
      border: none;
    }
    .search_text {
      height: 53px;
      background-color: #fab300;
      border-radius: 6px;
      align-items: center;
      width: 100%;
      border: none;
      line-height: 53px;
      vertical-align: middle;
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .title {
    margin-top: 20px;
  }
  .f18 {
    font-size: 20px;
  }
  .navBox {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-flow: wrap;
    .nav_item_type {
      display: flex;
      .type_true {
        color: #fab300;
        padding-left: 15px;
        font-size: 20px;
      }
      .type_false {
        font-size: 20px;
        margin-left: 15px;
      }
    }
  }
  .navBox2 {
    display: flex;
    justify-content: flex-start;
    min-height: 530px;
    align-content: flex-start;
    flex-flow: wrap;
    .show {
      height: 530px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .nav_item_festival {
      display: flex;
      width: 25%;
      margin: 20px 0 0 0;
      flex-direction: column;
      align-items: center;
      .navItem_img_festival {
        height: 118px;
        width: 118px;
        border-radius: 6px;
        overflow: hidden;
      }
      .navItem_img_festival:hover {
        cursor: pointer;
      }
      .navItem_text__festival {
        margin-top: 9px;
        font-size: 14px;
        color: #000000ff;
      }
    }
  }

  .point {
    text-align: right;
    margin: 20px;
    margin-right: 40px;
  }
  .tag {
    margin: 26px 0 21px;
    .tagBox {
      width: 100%;
      .tagItem {
        width: 18%;
        margin: 0 1%;
        font-size: 21px;
      }
      .activeTag {
        color: #f8b229;
      }
    }
  }
  .view {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
